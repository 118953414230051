<template>
    <DashboardWrapper>
        <div class="container mx-auto">
            <div class="mx-4 sm:mx-8">
                <h2 class="text-white pb-4 mb-4 uppercase text-xl font-extrabold">
                    Account Instellingen
                </h2>
                <div class="grid grid-cols-1 md:grid-cols-2 md:gap-x-20 lg:gap-x-32 gap-y-8 md:gap-y-12 mt-4 md:mt-6">
                    <div class="w-full">
                        <FormSection title="Persoonsgegevens" hasSaveButton="true">
                            <TextInput label="Voornaam" value="Voornaam" theme="dark" class="mt-4 md:mt-6"/>
                            <TextInput label="Achternaam" value="Achternaam" theme="dark" class="mt-4 md:mt-6"/>
                        </FormSection>
                    </div>
                    <div class="w-full">
                        <FormSection title="Account">
                            <div class="flex">
                                <TextInput label="E-mailadres" value="JouwEmail@gmail.com" disabled="true" theme="dark" class="mt-4 md:mt-6 w-full"/>
                                <div class="text-right md:mt-6 self-end">
                                    <button type="submit" @click="showModal('changeEmail')" class="button">Aanpassen</button>
                                </div>
                            </div>
                            <div class="flex">
                                <TextInput label="Wachtwoord" value="Unknown" disabled="true" type="password" theme="dark" class="mt-4 md:mt-6 w-full"/>
                                <div class="text-right md:mt-6 self-end">
                                    <button type="submit" @click="showModal('changePassword')" class="button">Aanpassen</button>
                                </div>
                            </div>
                        </FormSection>
                    </div>
                </div>
            </div>
        </div>  
    </DashboardWrapper>
    <!-- Change Email Modal -->
    <Modal v-if="modalVisible && selectedModal == 'changeEmail'" @closed="closeModal" >
        <template v-slot:header>
            <div class="flex">
                <svg  class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    E-mailadres wijzigen
                </h2>
            </div>
        </template>
        <template v-slot:body>    
            <TextInput label="Nieuw e-mailadres" border="enable" placeholder="Nieuw e-mailadres" class="mt-10"/>
            <TextInput label="Wachtwoord" border="enable" type="password" placeholder="Wachtwoord" class="mt-5"/>
        </template>
        <template v-slot:footer>    
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Wijzigen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>  
    </Modal>  
    <!-- Change Password Modal -->
    <Modal v-if="modalVisible && selectedModal == 'changePassword'" @closed="closeModal" >
        <template v-slot:header>
            <div class="flex">
                <svg  class="w-6 mr-1 text-scorelit-gray" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
                </svg>
                <h2 class="uppercase text-xl font-extrabold">
                    Wachtwoord wijzigen
                </h2>
            </div>
        </template>
        <template v-slot:body>    
            <TextInput label="Huidige wachtwoord" border="enable" type="password" placeholder="Huidige wachtwoord" class="mt-10"/>
            <TextInput label="Nieuwe wachtwoord" border="enable" type="password" placeholder="Nieuwe wachtwoord" class="mt-5"/>
            <TextInput label="Bevestig nieuwe wachtwoord" border="enable" type="password" placeholder="Bevestig nieuw wachtwoord" class="mt-5"/>
        </template>
        <template v-slot:footer>    
            <div class="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                <button type="button" class="button w-full inline-flex justify-center sm:ml-3 sm:w-auto ">
                    Wijzigen
                </button>
                <button @click="closeModal" type="button" class="button-black w-full inline-flex justify-center mt-3 sm:mt-0 sm:w-auto ">
                    Annuleren
                </button>
            </div>
        </template>  
    </Modal>  
</template>

<script>
import DashboardWrapper from "@/views/wrappers/Dashboard";
import FormSection from "@/views/wrappers/partials/FormSection";
import TextInput from "@/components/forms/TextInput";
import Modal from "@/components/modals/Modal";

export default {
    name: "ProfileSettings",
    components: {
        DashboardWrapper,
        FormSection,
        TextInput,
        Modal,
    },

    data() {
        return {
            modalVisible: false,
            selectedModal: '',
        }
    },    

    methods: {
        closeModal() {
            this.modalVisible = false;
        },

        showModal(type) {
            this.selectedModal = type;
            this.modalVisible = true;        
        },
    },
}
</script>